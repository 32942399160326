<template>
    <div class="register-container">
      <div class="bgcolor"></div>
      <div class="login-label">
        <div class="title-container">
          <h3 class="title">江苏省工程建设地方标准编制管理系统</h3>
          <div class="first">修改密码</div>
        </div>
        <div class="shuru">
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            class="register-form"
            label-width="100px"          
          >
            <el-form-item label="用户名" prop="username">
              <el-input v-model="ruleForm.username" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="旧密码" prop="password">
              <el-input v-model="ruleForm.password" show-password placeholder="请输入旧密码"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="checkPass">
              <el-input v-model="ruleForm.checkPass" show-password placeholder="请输入新密码"> </el-input>
            </el-form-item>
            <el-button
              class="regitserbtn"
              type="primary"
              @click="submitForm('ruleForm')"
            >
              修改
            </el-button>
          </el-form>
          <div class="logcont2">
            <div class="forget">
              <router-link to="../login">
                <span style="color: #3082e2;text-decoration: none;">点击登录</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import JiangSu from "@assets/jiangsu_se.json";
  import { settingPass } from "@api/user";
  export default {
    data() {
      var validatePass2 = (rule, value, callback) => {
        // console.log("rule", rule);
        if (value === "") {
          callback(new Error("请输入新密码"));
        } else if (value === this.ruleForm.password) {
          callback(new Error("新密码与旧密码不能一样!"));
        } else {
          callback();
        }
      };
      return {
        searchText: '', // 输入框内容
        searchResult: [], // 搜索结果
        labelPosition: "top",
        cityOptions: JiangSu.city,
        type: [
          {
            type: "array",
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
  
        ruleForm: {
          username: "",
          password: "",
          checkPass: "",
          right_company: "",
          telephone:"",
          city:""
  
        },
        rules: {
          username: [
            { required: true, message: "请输入用户名", trigger: "blur" },
            { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" },
          ],
          password: [
            { required: true, message: "请输入旧密码", trigger: "blur" },
            { min: 6, max: 20, message: "长度在 6到 20个字符", trigger: "blur" },
          ],
          checkPass: [
            { required: true, message: "请输入新密码", trigger: "blur" },
            { validator: validatePass2, trigger: "blur" },
          ],
        },
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            //console.log(this.ruleForm)
            // this.ruleForm = {
            //   ...this.ruleForm,
            //   class: 4,
            // };
            let params = {
              username: this.ruleForm.username,
              old_password: this.ruleForm.password,
              new_password: this.ruleForm.checkPass,
            };
            settingPass(params)
              .then((res) => {
                this.$message.success("修改成功");
                this.$router.push({ path: "/login" });
              })
              .catch((err) => {
                //console.log(err)
                //this.$message.error(err.message);
              });
          } else {
            //console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
  };
  </script>
  <style scoped>

  /deep/.el-select {
    width: 100%;
  }
  /deep/.el-form-item__label {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }
  </style>
  <style lang="scss" scoped>
  $bg: #ffffff;
  $dark_gray: #889aa4;
  $light_gray: #eee;
  .bgcolor {
    width: 50%;
    height: 100vh;
    background-color: #3082e2;
    // background-image: url("https://cloud.cdmp.tech/ccf0fa5c5dcfcc8b152e36068b699f00.png");
    // background-size: cover;
  }
  .shuru {
    margin: 0 auto;
    width: 360px;
    height: 360px;
    border-radius: 20px;
    // padding: 70px;
  }
  .logcont2 {
    margin-top: 27px;
    .forget {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-align: center;
    }
  }
  .login-label {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .register-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
    background-size: 100% 100%;
  
    .register-form {
      position: relative;
      // width: 520px;
      // max-width: 100%;
      // padding: 160px 35px 0;
      margin: 0 auto;
      overflow: hidden;
    }
  }
  .title-container {
    position: relative;
  
    .title {
      font-size: 36px;
      color: #333333;
      margin: 0px auto 20px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  .regitserbtn {
    width: 100%;
    background: #3082e2;
  }
  
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .first {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    margin: 40px 0;
    text-align: center;
  }
  </style>
  